import { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { SOL_ADDRESS } from '../../../config.js'
import { Conditional } from '../../components/index.js'
import { usePositions } from '../../hooks/usePositions.js'
import { Layout } from '../../pages/layout/Layout.js'
import { usePriceContext } from '../../providers/PriceProvider.js'
import { useTokensContext } from '../../providers/TokensProvider.js'
import { PositionTabControl } from './components/index.js'

/**
 * @todo Dupe, needs to be removed
 */
enum Tabs {
  OPEN = 'open',
  CLOSED = 'closed',
}

// type Props = {
//   tokens?: TokenInfo[]
// }

const getCurrentTabFromPath = (path: string): Tabs => {
  return path.includes(Tabs.CLOSED) ? Tabs.CLOSED : Tabs.OPEN
}
export const PositionsPage = () => {
  const { allTokens } = useTokensContext()
  // useEffect(() => {
  //   refetch()
  // }, [refetch])

  const navigate = useNavigate()
  const location = useLocation()
  const { openPositions, isLoading } = usePositions()
  const currentTab = getCurrentTabFromPath(window.location.pathname)
  const handleTabChange = (tab: Tabs) => navigate({ pathname: `/positions/${tab}`, search: location.search })
  const baseCurrencies = new Set(openPositions.map(position => position.pool.baseCurrency?.address).filter(t => t !== undefined))

  const { priceUsdc, setTokenIds } = usePriceContext()

  useEffect(() => {
    setTokenIds([...baseCurrencies, SOL_ADDRESS])

    return () => setTokenIds([])
  }, [openPositions])

  return (
    <Layout className='relative justify-start'>
      <div className='relative flex w-full flex-1 flex-col text-center text-xs'>
        <PositionTabControl currentTab={currentTab as Tabs} quantityOfActivePositions={openPositions.length} setCurrentTab={handleTabChange}/>
        <Conditional if={!isLoading}>
          <Outlet context={{ allTokens, collateralsUSD: priceUsdc }}/>
        </Conditional>
        <Conditional if={isLoading}>
          <div className='loading loading-spinner loading-lg m-auto'/>
        </Conditional>
      </div>
    </Layout>
  )
}
