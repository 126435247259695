import '@/app/styles.css'
import '@solana/wallet-adapter-react-ui/styles.css'

import { NotFoundPage } from '@/app/pages/404/NotFoundPage'
import { MainPage } from '@/app/pages/main/MainPage'
import { RewardsPage } from '@/app/pages/rewards/RewardsPage'
import { ClaimWidget, StakeUnstakeWidget } from '@/app/pages/stake/components'
import { StakePage } from '@/app/pages/stake/StakePage'
import { LavarageProvider } from '@/app/providers/LavarageProvider.js'
import { StendingProvider } from '@/app/providers/StendingProvider.js'
import { useWallet } from '@solana/wallet-adapter-react'
import { useEffect } from 'react'
import { Navigate, Route, Routes, useSearchParams } from 'react-router-dom'
import { referralService } from '../services/ReferralService.js'
import { STAKE_ENABLED } from './app.config.js'
// import { useTokensQuery } from './hooks/queries/useTokensQuery.js'
import { useCheckVersionAndUpdate } from './hooks/useCheckVersionAndUpdate.js'
import { ClosedPositions } from './pages/positions/components/ClosedPositions.js'
import { OpenPositions } from './pages/positions/components/OpenPositions.js'
import { PositionsPage } from './pages/positions/PositionsPage.js'
import { TrendingPage } from './pages/trending/TrendingPage.js'
import { PositionsProvider } from './providers/PositionsProvider.js'
import { PriceProvider } from './providers/PriceProvider.js'
import { TephrasProvider } from './providers/TephrasProvider.js'
import { TokensProvider } from './providers/TokensProvider.js'
import { WalletInfoProvider } from './providers/WalletInfoProvider.js'

export function App() {
  const [searchParams] = useSearchParams()
  const { publicKey } = useWallet()
  const referralCode = searchParams.get('ref') ?? searchParams.get('referrer')

  useCheckVersionAndUpdate()
  useEffect(() => {
    if (publicKey) {
      const address = publicKey.toBase58()
      referralService.generateReferralCode(address)

      if (referralCode) {
        referralService.registerReferee(address, referralCode, false)
      }

      if (address && (window as any)?.MetaCRMWidget?.manualConnectWallet) {
        (window as any).MetaCRMWidget.manualConnectWallet(address)
      }

      const handleConnectWidget = () => {
        if (address) {
          (window as any).MetaCRMWidget.manualConnectWallet(address)
        }
      }

      document.addEventListener('MetaCRMLoaded', handleConnectWidget)

      return () => {
        document.removeEventListener('MetaCRMLoaded', handleConnectWidget)
      }
    }
  }, [publicKey])

  return (
    <LavarageProvider>
      <WalletInfoProvider userAddress={publicKey?.toBase58()}>
        <TokensProvider>
          <PositionsProvider userAddress={publicKey?.toBase58()}>
            <TephrasProvider userAddress={publicKey?.toBase58()}>
              <PriceProvider>
                <StendingProvider>
                  <Routes>
                    {['/', '/long', '/swap', '/short'].map(path => (
                      <Route key={path} element={<MainPage/>} path={path}/>
                    ))}
                    <Route element={<PositionsPage/>} path='/positions'>
                      <Route index element={<Navigate replace to={`/positions/open?${searchParams.toString()}`}/>}/>
                      <Route element={<OpenPositions/>} path='open'/>
                      <Route element={<ClosedPositions/>} path='closed'/>
                    </Route>
                    <Route element={<TrendingPage/>} path='/trending'/>
                    <Route element={<RewardsPage/>} path='/rewards'/>
                    {STAKE_ENABLED && (
                      <Route element={<StakePage/>} path='/stake'>
                        <Route index element={<StakeUnstakeWidget mode='stake'/>}/>
                        <Route element={<StakeUnstakeWidget mode='unstake'/>} path='unstake'/>
                        <Route element={<ClaimWidget/>} path='claim'/>
                      </Route>
                    )}
                    <Route element={<NotFoundPage/>} path='*'/>
                  </Routes>
                </StendingProvider>
              </PriceProvider>
            </TephrasProvider>
          </PositionsProvider>
        </TokensProvider>
      </WalletInfoProvider>
    </LavarageProvider>
  )
}
