import { useWallet } from '@solana/wallet-adapter-react'
// import { MarketingModalImg } from '../../../assets/index.js'
import { Button, Conditional, LSpan, Tip } from '../../components/index.js'
// import { MarketingModal } from '../../components/MarketingModal.js'
import { useTephras } from '../../hooks/useTephras.js'
import { Layout } from '../layout/Layout.js'
import { LeaderBoard } from './components/LeaderBoard'

export function RewardsPage() {
  const { connected } = useWallet()
  const { isLoading, data } = useTephras()

  return (
    <Layout backgroundType='rewards'>
      {/* <MarketingModal
        ctaText='Optional CTA button'
        ctaUrl='https://lavarage.gitbook.io/lavarage/tephra'
        delay={2000}
        forceReappearKey='default_campaign7'
        ImageComponent={<MarketingModalImg style={{ width: '100%', height: 'auto', objectFit: 'contain' }}/>}
        title='Optional title of the Marketing modal!'
      >
        <div className='bg-[#FF433F1A] p-[10px] text-center text-sm'>
          <span>Custom text</span>
          {' '}
          <span className='text-green'>with formatting</span>
          {' '}
          <span>and</span>
          {' '}
          <a className='text-purple' href='https://lavarage.gitbook.io/lavarage/tephra' rel='noreferrer' target='_blank'>
            link
          </a>
          <br/>
          <span>Optional Text: Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem</span>
        </div>
      </MarketingModal> */}
      <div className='relative flex w-full max-w-[720px] flex-col items-center text-center' id='core'>
        <span className='font-berpatroli desk:text-[60px] mb-[30px] text-[40px] leading-[65px]'>EARN REWARDS</span>
        <div className='desk:text-[18px] mb-[30px] flex max-w-[540px] flex-col items-center justify-center text-base leading-snug'>
          <p className='font-bold'>
            <span>Trade on Lavarage to earn </span>
            <span className='bg-gradient px-2 py-1 font-bold'>Tephra</span>
            <span>—the exclusive rewards of the Lavarage ecosystem—from every transaction, dating back to </span>
            <span className='underline'>day one</span>
            .
          </p>
        </div>
        <div className='mb-[30px]'>
          <Conditional if={connected}>
            <div className='bg-background/15 text-l rounded-lg px-4 py-2'>
              <span>Your Tephras:</span>
              {' '}
              <LSpan className='font-bold' isLoading={isLoading} text={data?.total.toLocaleString()}/>
            </div>
          </Conditional>
          <Conditional if={!connected}>
            <Tip>
              <span className='text-left'>
                Connect your wallet in order to view your
                {' '}
                <b>Tephra</b>
                {' '}
                balance.
              </span>
            </Tip>
          </Conditional>
        </div>
        <section className='panel-light mb-[30px] flex max-w-[440px] flex-col items-center justify-center rounded-[18px] p-5'>
          <p className='mb-[20px] text-base leading-[19px]'>
            <span>Start earning extra</span>
            <span className='font-bold'>{' Tephra '}</span>
            <span>also through social tasks!</span>
          </p>
          <div className='mb-[20px] flex items-center justify-center gap-[10px]'>
            <a href='https://app.galxe.com/quest/lavarage' rel='noreferrer' target='_blank'>
              <Button color='gradient' size='m'>
                Earn Now
              </Button>
            </a>
            <a href='https://lavarage.gitbook.io/lavarage/tephra' rel='noreferrer' target='_blank'>
              <Button color='main' size='m'>
                How It Works
              </Button>
            </a>
          </div>
          <p className='text-alt text-sm'>
            <span className='text-main font-bold'>Tip:</span>
            <span className='text-main'>{' Boost your earnings by holding a '}</span>
            Lava Rock
            <span className='text-main'>.</span>
          </p>
        </section>
        <LeaderBoard/>
      </div>
    </Layout>
  )
}
