import { type TakeProfitModalButtonMode } from '@/app/pages/positions/components'

type GetTakeProfitModalButtonModeProps = {
  isOnline: boolean
  confirming: boolean
  isRestricted: boolean
  isSetTakeProfit: boolean
  isModifyTakeProfit: boolean
  isRemoveTakeProfit: boolean
}

export const getTakeProfitModalButtonMode = ({
  isOnline,
  confirming,
  isRestricted,
  isSetTakeProfit,
  isModifyTakeProfit,
  isRemoveTakeProfit,
}: GetTakeProfitModalButtonModeProps): TakeProfitModalButtonMode => {
  switch (true) {
    case !isOnline:
      return 'no-connection'
    case confirming:
      return 'confirming'
    case isRestricted:
      return 'restricted'
    case isSetTakeProfit:
      return 'createTakeProfit'
    case isModifyTakeProfit:
      return 'modifyTakeProfit'
    case isRemoveTakeProfit:
      return 'removeTakeProfit'
    default:
      return 'restricted'
  }
}
