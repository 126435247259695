import { useWallet } from '@solana/wallet-adapter-react'
import clsx from 'clsx'
import type { HTMLAttributes } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { IconPosition, IconPositionActive, IconStake, IconStakeActive, IconTrade, IconTradeActive, IconTrending, IconTrendingActive } from '../../assets/index.js'
import { trackNavigationClickGA } from '../analytics.js'
import { clsxm } from '../helpers/clsxm.js'

type NavItemProps = HTMLAttributes<HTMLButtonElement> & {
  path: string
  paths?: string[]
  isExactPath?: boolean
  icon: JSX.Element
  iconActive: JSX.Element
  label: string
}

const NavItem = ({
  className,
  icon,
  iconActive,
  isExactPath,
  label,
  path,
  paths,
  ...props
}: NavItemProps) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isActive = isExactPath ? (location.pathname === path || paths?.find(p => location.pathname.startsWith(p))) : location.pathname.startsWith(path)
  const iconElement = isActive ? iconActive : icon

  const onClick = () => {
    trackNavigationClickGA(`${label} Page`)
    navigate({ pathname: path, search: location.search })
  }
  return (
    <button
      {...props}
      className={clsxm(className, 'desk:hover:opacity-70 flex items-center justify-center gap-1 text-base transition', { 'pointer-events-none': isActive })}
      role='link'
      onClick={onClick}
    >
      {iconElement}
      <span className={`${isActive ? 'text-alt' : 'desk:block hidden'}`}>{label}</span>
    </button>
  )
}

export function MainNav({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <nav {...props} className={clsx(className, 'desk:gap-5 flex items-center gap-4')} role='navigation'>
      <NavItem isExactPath icon={<IconTrade className='h-5 w-auto'/>} iconActive={<IconTradeActive className='h-5 w-auto'/>} label='Trade' path='/' paths={['/long', '/swap', '/short']}/>
      <NavItem icon={<IconPosition className='h-5 w-auto'/>} iconActive={<IconPositionActive className='h-5 w-auto'/>} label='Positions' path='/positions'/>
      <NavItem icon={<IconTrending className='h-5 w-auto'/>} iconActive={<IconTrendingActive className='h-5 w-auto'/>} label='Trending' path='/trending'/>
      <NavItem icon={<IconStake className='h-5 w-auto'/>} iconActive={<IconStakeActive className='h-5 w-auto'/>} label='Stake' path='/stake'/>
    </nav>
  )
}
