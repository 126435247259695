import { type HTMLAttributes } from 'react'
import { useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { type TimeFrameData, type TrendingTokenEntity } from '../../../../../functions/database/index.js'
import { IconDiscordGrey, IconLock, IconTelegramGrey, IconWebGrey, IconXGrey } from '../../../../assets/index.js'
import { SOL_TOKEN } from '../../../../config.js'
import { formatPercent } from '../../../../utils/index.js'
import { Conditional } from '../../../components/Conditional.js'
import { CopyToBuffer } from '../../../components/CopyToBuffer.js'
import { Hint } from '../../../components/Hint.js'
import { TokenImage } from '../../../components/TokenImage.js'
import { useTokensContext } from '../../../providers/TokensProvider.js'
import { usePreferencesStore } from '../../../stores/index.js'
import { ChecklistCol } from './ChecklistCol.js'

type Props = HTMLAttributes<HTMLDivElement> & {
  timeFrame: keyof TimeFrameData
  trendingTokens: TrendingTokenEntity[]
  isTrendingTokensLoading: boolean

}

const formatK = (num: number) => {
  if (num < 100_000) return `$${(num / 1000).toFixed(1)}K`
  if (num < 100_000_000) return `$${(num / 1_000_000).toFixed(1)}M`
  if (num < 100_000_000_000) return `$${(num / 1_000_000_000).toFixed(1)}B`
  return `$${(num / 1_000_000).toFixed(1)}M`
}

export const TrendingTable = ({
  timeFrame, trendingTokens, isTrendingTokensLoading,
}: Props) => {
  const { allTokens } = useTokensContext()
  const [baseTokenLong, setBaseTokenLong, quoteTokenLong, setQuoteTokenLong] = usePreferencesStore(useShallow(s => [
    s.baseTokenLong,
    s.setBaseTokenLong,
    s.quoteTokenLong,
    s.setQuoteTokenLong,
  ]))
  const navigate = useNavigate()
  const handleTokenClick = (selectedToken: any) => {
    const selectedTokenInfo = allTokens.find(token => token.address === selectedToken.address)
    if (!selectedTokenInfo) {
      console.error('Token not found in tokens query:', selectedToken)
      return
    }
    if (!selectedTokenInfo.whitelistedForAddress?.includes(SOL_TOKEN.address)) {
      console.error('Token not whitelisted for SOL:', selectedToken)
      return
    }
    setBaseTokenLong(selectedTokenInfo)
    setQuoteTokenLong(SOL_TOKEN)
    navigate('/')
  }
  const validTokens = trendingTokens.filter(token => token.tradeData?.timeFrameData?.[timeFrame])

  return (

    <div className=' overflow-x-auto'>
      <table className='table-xs table-pin-cols table-zebra table'>
        <thead className='text-gray'>
          <tr className='bg-background text-center'>
            <th className='!bg-background'>Token</th>
            <td>Mkt Cap</td>
            <td>{`${timeFrame} Volume`}</td>
            <td>{`${timeFrame} Change`}</td>
            <td>{`${timeFrame} Txs`}</td>
            <td>Liquidity</td>
            <td>
              <div className='flex items-center justify-center gap-1'>
                <span>Checklist</span>
                <Hint className='block' position='left' width={180}>
                  <div className='flex !w-[180px] items-center'>
                    <ul className='text-main w-10 list-disc space-y-2 pl-5 text-sm font-normal opacity-70'>
                      <li >
                        <span className='font-bold'>Freeze Auth</span>
                        <br/>
                        <span className='font-bold'>Disabled:</span>
                        {' '}
                        <span>
                          Green if
                          <br/>
                          freeze authority is
                          <br/>
                          disabled
                        </span>
                      </li>
                      <li>
                        <span className='font-bold'>Mint Auth Disabled:</span>
                        <br/>
                        Green if mint
                        <br/>
                        authority is disabled
                      </li>
                      <li>
                        <span className='font-bold'>Top 10 Holders:</span>
                        <br/>
                        Green if top 10
                        <br/>
                        holders owns less
                        <br/>
                        than 15% of the
                        <br/>
                        tokens
                      </li>
                    </ul>
                  </div>
                </Hint>
              </div>
            </td>
            {/* <th className='!bg-background'>
              <span className='flex items-center gap-1'>
                <span className='desk:block hidden'>Quick</span>
                <span>Buy</span>
              </span>
            </th> */}
          </tr>
        </thead>
        <Conditional if={isTrendingTokensLoading}>
          <tbody>
            <tr>
              <td className='text-center' colSpan={7}>
                Loading...
              </td>
            </tr>
          </tbody>
        </Conditional>
        <Conditional if={!isTrendingTokensLoading && validTokens && validTokens.length !== 0}>
          <tbody>
            {validTokens.map((tokenInfo, index) => (
              <tr key={index} onClick={handleTokenClick.bind(null, tokenInfo)}>
                <th>
                  <div className='flex items-center justify-center gap-2'>
                    <TokenImage altName={tokenInfo?.metaData?.name} logoURI={tokenInfo?.metaData?.logo_uri}/>
                    <div className='flex flex-col gap-[5px]'>
                      <div className='flex h-[15px] min-w-[80px] items-center justify-start gap-[2px]'>
                        <span className=' text-xs leading-[14px]'>{tokenInfo.metaData?.symbol}</span>
                        <CopyToBuffer textToCopy={tokenInfo.address} onClick={event => event.stopPropagation()}/>
                      </div>
                      <Conditional if={tokenInfo.metaData.website !== '' || tokenInfo.metaData.twitter !== '' || tokenInfo.metaData.discord !== '' || tokenInfo.metaData.telegram !== ''}>
                        <div className='flex items-center gap-2'>
                          <Conditional if={tokenInfo.metaData.website !== ''}>
                            <a className='text-xs opacity-40 transition hover:opacity-100' href={tokenInfo.metaData.website} rel='noreferrer' target='_blank' onClick={event => event.stopPropagation()}><IconWebGrey/></a>
                          </Conditional>
                          <Conditional if={tokenInfo.metaData.twitter !== ''}>
                            <a className='text-xs opacity-40 transition hover:opacity-100' href={tokenInfo.metaData.twitter} rel='noreferrer' target='_blank' onClick={event => event.stopPropagation()}><IconXGrey/></a>
                          </Conditional>
                          <Conditional if={tokenInfo.metaData.discord !== ''}>
                            <a className='text-xs opacity-40 transition hover:opacity-100' href={tokenInfo.metaData.discord} rel='noreferrer' target='_blank' onClick={event => event.stopPropagation()}><IconDiscordGrey/></a>
                          </Conditional>
                          <Conditional if={tokenInfo.metaData.telegram !== ''}>
                            <a className='text-xs opacity-40 transition hover:opacity-100' href={tokenInfo.metaData.telegram} rel='noreferrer' target='_blank' onClick={event => event.stopPropagation()}><IconTelegramGrey/></a>
                          </Conditional>
                        </div>
                      </Conditional>
                    </div>
                  </div>
                </th>
                <td className='text-sm'>
                  <span className='flex justify-center'>
                    {formatK(tokenInfo.overviewData?.mc)}
                  </span>
                </td>
                <td>
                  <div className='flex flex-col items-center justify-center text-sm'>
                    <span className={tokenInfo.tradeData.timeFrameData[timeFrame].volume === 0 ? 'text-gray' : 'text-main'}>{formatK(tokenInfo.tradeData.timeFrameData[timeFrame].volume)}</span>
                    <span>
                      <span className={tokenInfo.tradeData.timeFrameData[timeFrame].volume_buy === 0 ? 'text-gray' : 'text-green'}>
                        {formatK(tokenInfo.tradeData.timeFrameData[timeFrame].volume_buy)}
                      </span>
                      <span>/</span>
                      <span className={tokenInfo.tradeData.timeFrameData[timeFrame].volume_sell === 0 ? 'text-gray' : 'text-alt'}>
                        {`${formatK(tokenInfo.tradeData.timeFrameData[timeFrame].volume_sell)}`}
                      </span>
                    </span>
                  </div>
                </td>
                <td className='text-sm'>
                  <div className='flex justify-center'>
                    <span className={formatPercent(tokenInfo.tradeData.timeFrameData[timeFrame].price_change_percent) === '±0%' ? 'text-gray' : tokenInfo.tradeData.timeFrameData[timeFrame].price_change_percent > 0 ? 'text-green' : 'text-alt'}>{formatPercent(tokenInfo.tradeData.timeFrameData[timeFrame].price_change_percent)}</span>
                  </div>
                </td>
                <td>
                  <div className='flex flex-col items-center text-sm'>
                    <span>{formatK(tokenInfo.tradeData.timeFrameData[timeFrame].trade)}</span>
                    <span>
                      <span className={tokenInfo.tradeData.timeFrameData[timeFrame].buy === 0 ? 'text-gray' : 'text-green'}>
                        {formatK(tokenInfo.tradeData.timeFrameData[timeFrame].buy)}
                      </span>
                      <span>/</span>
                      <span className={tokenInfo.tradeData.timeFrameData[timeFrame].sell === 0 ? 'text-gray' : 'text-alt'}>
                        {`${formatK(tokenInfo.tradeData.timeFrameData[timeFrame].sell)}`}
                      </span>
                    </span>
                  </div>
                </td>
                <td >
                  <div className='flex flex-col items-center text-sm'>
                    <span>{formatK(tokenInfo.overviewData.liquidity)}</span>
                    <Conditional if={false}>
                      <div className='flex items-center'>
                        <span className={tokenInfo.lockedLiquidityData.totalLockedLiquidity > 0 ? 'text-green' : 'text-alt'}>
                          {tokenInfo.lockedLiquidityData.liquidity ? Math.round(tokenInfo.lockedLiquidityData.totalLockedLiquidity / tokenInfo.lockedLiquidityData.liquidity * 100) : '0'}
                          %
                        </span>
                        <IconLock/>
                      </div>
                    </Conditional>
                  </div>
                </td>
                <td className='flex justify-center gap-[5px] text-[10px]'>
                  <ChecklistCol
                    isCheckPassed={tokenInfo.securityData.freezeAuthority === null}
                    text='Freeze Auth
                    Disabled'
                  />
                  <ChecklistCol
                    isCheckPassed={tokenInfo.securityData.ownerAddress === null}
                    text='Mint Auth
                    Disabled'
                  />
                  <ChecklistCol
                    exactValue={tokenInfo.securityData.top10HolderPercent}
                    isCheckPassed={tokenInfo.securityData.top10HolderPercent !== null && tokenInfo.securityData.top10HolderPercent < 0.15}
                    text='Top 10
                    Holders'
                  />
                </td>
                {/* <th>
                <button className='bg-alt/20 hover:bg-alt/40 desk:w-[62px] flex h-[32px] items-center gap-0 rounded-[8px] p-0 transition'>
                  <IconLightning/>
                  <span className='desk:block hidden'>BUY</span>
                </button>
              </th> */}
              </tr>
            ))}
          </tbody>
        </Conditional>
      </table>

    </div>

  )
}
