import { calculatePositionMetrics } from '@/app/pages/positions/utils'
import { IconBigLavarageLogo, IconBigTwitterLogo } from '@/assets'
import { type Position } from '@lavarage/entities'
import { forwardRef } from 'react'
import { formatTimestampToGMT } from '../../../../utils/formatters/formatTimestampToGMT.js'
import { formatSol } from '../../../../utils/index.js'
import { Conditional, TokenImage } from '../../../components/index.js'
import { useConfirmedSell } from '../../../hooks/useConfirmedSell.js'
import { useNftAccess } from '../../../hooks/useNftAccess.js'

export type TwitterShareProps = {
  position: Position
  prices?: Record<string, { price: number }>
  timezone: string
  refCode?: string
  onExit?: () => void
}

export const TwitterShareComponent = forwardRef<HTMLDivElement, TwitterShareProps>(({
  position,
  prices,
  timezone,
  onExit,
}, ref) => {
  const leverage = position.initialLeverage.toNumber().toFixed(1)
  const baseSymbol = position.pool?.baseCurrency?.symbol
  const entryPrice = position.entryPrice.toNumber()
  const { pool: { baseCurrency, quoteCurrency } } = position
  if (!baseCurrency || !quoteCurrency) return null
  const collateralPrice = prices?.[baseCurrency.address]?.price
  const quotePriceInUSD = prices?.[quoteCurrency?.address]?.price
  const { hasLavaRockNFT } = useNftAccess()
  const slippage = 0.5
  const confirmedSellInSol = useConfirmedSell(position, hasLavaRockNFT, Number(slippage))
  const {
    currentPrice,
    roi,
    finalRoi,
  } = calculatePositionMetrics(position, quotePriceInUSD, collateralPrice, timezone, confirmedSellInSol)
  const price = position.status === 'active' ? formatSol(currentPrice) : formatSol(position.price.toNumber())

  const bnRoi = position.status === 'active' ? roi : finalRoi
  const dispRoi = formatSol(bnRoi.toNumber(), bnRoi.isLessThan(10) ? 1 : 0)
  const isLessThenMin = bnRoi.isLessThan(1)
  const isBuyMode = isLessThenMin || position.status === 'repaid' || position.status === 'liquidated'
  const formattedOpenDate = formatTimestampToGMT(position.openDate.getTime() / 1000, timezone)
  const formattedClosedDate = position.closeDate ? formatTimestampToGMT(position.closeDate.getTime() / 1000, timezone) : '--'
  const isActivePosition = position.status === 'active'
  return (
    <div
      ref={ref}
      className='font-berpatroli share-bg flex h-[900px] w-[1600px] flex-col px-[120px] py-[30px]'
    >
      <div className='mb-[50px] flex justify-between'>
        <div className='flex items-center gap-[15px]'>
          <IconBigLavarageLogo/>
          <span className='text-[40px] text-white'>
            {' '}
            <span className='mr-px'>APP</span>
            <span>.LAVARAGE.XYZ</span>
          </span>
        </div>
        <div className='flex items-center gap-2'>
          <IconBigTwitterLogo/>
          <span className='text-[40px] text-white'>@LAVARAGEXYZ</span>
        </div>
      </div>
      <Conditional if={isBuyMode}>
        <div className='relative mb-[20px] flex items-center'>
          <span className='text-gradient flex h-[172px] items-center text-[200px]'>BUY</span>
        </div>
      </Conditional>
      <div className='mb-[20px] flex h-[135px] items-center justify-start'>
        <TokenImage isBig altName={baseCurrency?.name ?? ''} logoURI={baseCurrency?.logoURI ?? ''}/>
        <span className='text-gradient ml-[20px] flex h-[135px] items-center text-[120px] '>{`${baseSymbol}`}</span>
      </div>
      <div className='text-main mb-[48px] flex flex-wrap justify-start '>
        <span className='h-[119px] whitespace-nowrap rounded-[20px] bg-[#FFDADA33]/20 px-[11px] py-[60px] text-[75px]'>
          {`${leverage}X LEVERAGE`}
        </span>
      </div>
      <Conditional if={!isBuyMode}>
        <div className='relative mb-[48px] flex items-center'>
          <div className='absolute left-0 top-[25px]'>
            <span className='text-[60px] text-white'>ROI</span>
          </div>
          <span className='text-gradient flex h-[155px] items-center text-[200px]'>+</span>
          <span className='text-gradient flex h-[200px] items-center text-[200px]'>{`${dispRoi}%`}</span>
        </div>
      </Conditional>
      <div className='flex h-[152px] items-center justify-between rounded-[20px] bg-[#FFDADA33]/20 px-[40px]'>
        <div className='flex w-[400px] flex-col gap-2 text-[40px]'>
          <span className='text-gradient2 flex h-[43px] items-center justify-center'>ENTRY PRICE</span>
          <span className='flex h-[43px] items-center justify-center text-white'>{`${formatSol(entryPrice)} ${quoteCurrency?.symbol}`}</span>
        </div>
        <Conditional if={isActivePosition}>
          <div className='flex w-[400px] flex-col gap-2 text-[40px]'>
            <span className='text-gradient2 flex h-[43px] items-center justify-center'>CURRENT PRICE</span>
            <span className='flex h-[43px] items-center justify-center text-white'>{`${price} ${quoteCurrency?.symbol}`}</span>
          </div>
        </Conditional>
        <div className='flex w-[400px] flex-col gap-2 text-[40px]'>
          <span className='text-gradient2 flex h-[43px] items-center justify-center'>OPENED ON</span>
          <span className='flex h-[43px] items-center justify-center text-white'>{formattedOpenDate}</span>
        </div>
        <Conditional if={!isActivePosition}>
          <div className='flex w-[400px] flex-col gap-2 text-[40px]'>
            <span className='text-gradient2 flex h-[43px] items-center justify-center'>CLOSED ON</span>
            <span className='flex h-[43px] items-center justify-center text-white'>{formattedClosedDate}</span>
          </div>
        </Conditional>
      </div>
    </div>
  )
})

TwitterShareComponent.displayName = 'TwitterShare'

export const TwitterShare = TwitterShareComponent
