import EmojiPicker, { Theme } from 'emoji-picker-react'
import Fuse from 'fuse.js'
import React, { useEffect, useMemo, useState, type ChangeEvent } from 'react'
import { IconSearch } from '../../../../assets/index.js'
import { trackTokenSearchGA } from '../../../analytics.js'

type SearchInputProps = {
  tokens: any[]
  onFilter: (filteredTokens: any[]) => void
}

export const SearchInput: React.FC<SearchInputProps> = ({ tokens, onFilter }) => {
  const [inputSearchValue, setInputSearchValue] = useState<string>('')
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState<boolean>(false)

  const fuse = useMemo(() => {
    return new Fuse(tokens, {
      keys: ['metaData.name', 'metaData.symbol', 'address'],
      threshold: 0.3,
    })
  }, [tokens])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (inputSearchValue.trim() === '') {
        onFilter(tokens)
      }
      else if (inputSearchValue.length === 44) {
        const exactMatch = tokens.find(token => token.address.toLowerCase() === inputSearchValue.toLowerCase())
        onFilter(exactMatch ? [exactMatch] : [])
      }
      else {
        const fuseResults = fuse.search(inputSearchValue)
        const filteredTokens = fuseResults.map(({ item }) => item)
        onFilter(filteredTokens)
      }
    }, 500)

    return () => clearTimeout(timer)
  }, [inputSearchValue, tokens, fuse, onFilter])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputSearchValue(e.target.value)
  }

  const handleEmojiClick = ({ emoji }: { emoji: string }) => {
    setInputSearchValue(prev => prev + emoji)
    setIsEmojiPickerVisible(false)
  }

  return (
    <div className='bg-main/10 desk:mb-0 desk:max-w-[239px] flex h-[36px] grow items-center justify-between rounded-[8px] px-[5px]'>
      <IconSearch/>
      <input
        className='h-[36px] w-full rounded-[8px] bg-transparent p-2 text-base outline-none placeholder:text-white placeholder:text-opacity-40'
        placeholder='Search...'
        type='text'
        value={inputSearchValue}
        onBlur={() => inputSearchValue !== '' && trackTokenSearchGA(inputSearchValue)}
        onChange={handleInputChange}
      />
      <button onClick={() => setIsEmojiPickerVisible(prev => !prev)}>
        <span aria-label='emoji-picker' className='text-base' role='img'>
          😉
        </span>
      </button>
      {isEmojiPickerVisible && (
        <div className='absolute bottom-0 z-10'>
          <EmojiPicker theme={Theme.DARK} onEmojiClick={handleEmojiClick}/>
        </div>
      )}
    </div>
  )
}
