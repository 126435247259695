import { Button } from '@/app/components'

export type TakeProfitModalButtonMode = 'no-connection' | 'confirming' | 'confirmed' | 'restricted' | 'createTakeProfit' | 'removeTakeProfit' | 'modifyTakeProfit'

type TakeProfitModalButtonProps = {
  takeProfitModalButtonMode: TakeProfitModalButtonMode
  createTakeProfit: () => Promise<void>
  removeTakeProfit: () => Promise<void>
  modifyTakeProfit: () => Promise<void>
}

export const TakeProfitModalButton = ({ takeProfitModalButtonMode, createTakeProfit, removeTakeProfit, modifyTakeProfit }: TakeProfitModalButtonProps) => {
  function getLabelByMode(mode: TakeProfitModalButtonMode) {
    switch (mode) {
      case 'no-connection':
        return 'No Connection'
      case 'confirming':
        return 'Confirming...'
      case 'confirmed':
        return 'Confirmed'
      case 'restricted':
        return 'Restricted'
      case 'createTakeProfit':
        return 'Set TP'
      case 'removeTakeProfit':
        return 'Remove TP'
      case 'modifyTakeProfit':
        return 'Modify TP'
      default:
        return 'Restricted'
    }
  }

  function getColorByMode(mode: TakeProfitModalButtonMode) {
    switch (mode) {
      case 'no-connection':
      case 'confirming':
      case 'confirmed':
        return 'main'
      case 'restricted':
      case 'createTakeProfit':
      case 'removeTakeProfit':
      case 'modifyTakeProfit':
        return 'alt'
      default:
        return 'main'
    }
  }

  function getClickHandlerByMode(mode: TakeProfitModalButtonMode) {
    switch (mode) {
      case 'createTakeProfit':
        return createTakeProfit()
      case 'removeTakeProfit':
        return removeTakeProfit()
      case 'modifyTakeProfit':
        return modifyTakeProfit()
      default:
        return null
    }
  }

  function isDisabledByMode(mode: TakeProfitModalButtonMode) {
    return mode === 'no-connection' || mode === 'restricted' || mode === 'confirming' || mode === 'confirmed'
  }

  return (
    <Button
      color={getColorByMode(takeProfitModalButtonMode)}
      disabled={isDisabledByMode(takeProfitModalButtonMode)}
      size='s'
      onClick={() => getClickHandlerByMode(takeProfitModalButtonMode)}
    >
      {getLabelByMode(takeProfitModalButtonMode)}
    </Button>
  )
}
