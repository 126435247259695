import { TokenService } from '@/services'
import { useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

const tokenService = new TokenService()

export const useTrendingTokensQuery = (list: 'all') => {
  // const fetchController = useRef<AbortController | null>(null)
  // const firstRenderRef = useRef(true)

  const fetchTokens = useCallback(async () => {
    // const localController = new AbortController()

    // if (fetchController.current) {
    //   fetchController.current.abort()
    // }
    // fetchController.current = localController

    try {
      if (list === 'all') {
        const fetchedTokens = await tokenService.getTrendingTokens()
        // if (fetchController.current !== localController) {
        // return
        // }
        return fetchedTokens
      }

      // if (localController.signal.aborted) return

      // firstRenderRef.current = false
    }
    catch (error) {
      // if (localController.signal.aborted) return
      console.error('Error fetching tokens:', error)
      return []
    }
    finally {
      // if (fetchController.current === localController) {
      //   fetchController.current = null
      // }
    }
  }, [list])

  const {
    data: trendingTokens,
    refetch,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['trendingTokens', list],
    queryFn: fetchTokens,
    refetchInterval: 60_000,
    retry: 5,
  })

  return { trendingTokens, refetch, isLoading, isError }
}
