import { Button, Conditional } from '@/app/components'
import { Modal } from '@/app/components/Modal'
import { useNetworkStatus } from '@/app/hooks/useNetworkStatus'
import { TakeProfitModalButton } from '@/app/pages/positions/components'
import { calculatePositionMetrics, getTakeProfitModalButtonMode } from '@/app/pages/positions/utils'
import { useLavarage } from '@/app/providers/LavarageProvider.js'
import { useAlertsStore } from '@/app/stores'
import { PROFIT_FEE, TAKE_PROFIT_FEE } from '@/config'
import { formatSol } from '@/utils/formatters'
import { type Position } from '@lavarage/entities'
import { useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useQueryClient } from '@tanstack/react-query'
import { BigNumber } from 'bignumber.js'
import { forwardRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { IconHintRed } from '../../../../assets/index.js'
import { trackCreateTakeProfitCancelledGA, trackCreateTakeProfitFailedGA, trackCreateTakeProfitSuccessGA, trackRemoveTakeProfitCancelledGA, trackRemoveTakeProfitFailedGA, trackRemoveTakeProfitSuccessGA } from '../../../analytics.js'
import { MIN_TAKE_PROFIT_PERCENT } from '../../../app.config.js'
import { LabelledValue } from '../../../components/LabelledValue.js'
import { sendTransaction } from '../../../helpers/sendTransaction.js'

type Props = {
  position: Position
  prices?: Record<string, { price: number }>
  timezone: string
  onExit?: () => void
}

export const TakeProfitModal = forwardRef<HTMLDivElement, Props>(({ position, prices, timezone, onExit }, ref) => {
  const [addAlert, removeAlert, loading, setLoading, setConfirming, confirming] = useAlertsStore(useShallow(state => [state.addAlert, state.removeAlert, state.loading, state.setLoading, state.setConfirming, state.confirming]))
  const isOnline = useNetworkStatus()
  const contractTakeProfitPrice = position.takeProfitPrice?.toNumber()
  const wallet = useWallet()
  const queryClient = useQueryClient()
  const { pool: { baseCurrency, quoteCurrency } } = position
  if (!baseCurrency || !quoteCurrency) return null
  const quoteSymbol = position.pool.quoteCurrency?.symbol as string
  const lavarages = useLavarage()
  const lavarage = quoteSymbol === 'SOL' ? lavarages[0] : lavarages[1]
  const collateralPrice = prices?.[baseCurrency.address]?.price
  const quotePriceInUSD = prices?.[quoteCurrency?.address]?.price
  const {
    currentPrice,
    liquidationPrice,
    priceChangePercentage,
  } = calculatePositionMetrics(position, quotePriceInUSD, collateralPrice, timezone)
  const minTakeProfitPercent = Math.max(MIN_TAKE_PROFIT_PERCENT, Math.round(priceChangePercentage.toNumber() + 1))
  const [takeProfitPercent, setTakeProfitPercent] = useState<number | null>(contractTakeProfitPrice !== undefined ? null : minTakeProfitPercent)
  const currentPriceBN = new BigNumber(currentPrice)
  const takeProfitPrice = position.entryPrice.times(1 + ((takeProfitPercent ?? 0) / 100))
  const currentPositionSize = position.collateralSize.times(currentPriceBN)
  const estimatedPositionSize = (currentPositionSize).times(1 + ((takeProfitPercent ?? 0) / 100) - (priceChangePercentage.toNumber() / 100))
  const sellFromPosition = position.collateralSize.times(takeProfitPrice).toNumber()
  const jupiterPlatformFee = Number(sellFromPosition) * TAKE_PROFIT_FEE / 100
  const assumedPnL = estimatedPositionSize.minus(position.initialPositionSize).minus(position.interestAccrued).toNumber()
  const profitFee = assumedPnL >= 0 ? assumedPnL * (PROFIT_FEE / 100) : 0
  const displayedTakeProfitFee = profitFee + jupiterPlatformFee
  const estimatedProfit = estimatedPositionSize.minus(position.borrowedAmount.plus(position.interestAccrued).plus(displayedTakeProfitFee))
  const isButtonDisabled = loading || confirming
  const isInvalidTakeProfit: boolean = takeProfitPercent === null || takeProfitPercent < minTakeProfitPercent

  // Logic for set, modify and remove take profit
  const isSetTakeProfit = contractTakeProfitPrice === undefined && takeProfitPrice.gt(position.entryPrice) && takeProfitPrice.gt(currentPrice) && !isInvalidTakeProfit
  const isModifyTakeProfit = contractTakeProfitPrice !== undefined && takeProfitPrice.gt(position.entryPrice) && takeProfitPrice.gt(currentPriceBN) && formatSol(takeProfitPrice.toNumber()) !== formatSol(contractTakeProfitPrice) && !isInvalidTakeProfit
  const isRemoveTakeProfit = contractTakeProfitPrice !== undefined && !isModifyTakeProfit
  const isRestricted = contractTakeProfitPrice === undefined && isInvalidTakeProfit

  const takeProfitModalButtonMode = getTakeProfitModalButtonMode({
    isOnline,
    confirming,
    isRestricted,
    isSetTakeProfit,
    isModifyTakeProfit,
    isRemoveTakeProfit,
  })

  const handleSetTakeProfit = async () => {
    try {
      setLoading(true)
      setConfirming(true)
      const tx = await lavarage.createTakeProfit(takeProfitPrice, position.pool.publicKey, new PublicKey(position.seed))
      const res = await sendTransaction(tx, addAlert, removeAlert, setConfirming, lavarage.program.provider, wallet, undefined, 'createTakeProfit', queryClient)
      if (res) {
        trackCreateTakeProfitSuccessGA()
        setTakeProfitPercent(takeProfitPercent)
      }
    }
    catch (error) {
      if (!(error as Error).message.includes('User rejected the request')) {
        trackCreateTakeProfitCancelledGA()
      }
      else {
        trackCreateTakeProfitFailedGA()
      }
    }
    finally {
      setConfirming(false)
      setLoading(false)
    }
  }

  const handleRemoveTakeProfit = async () => {
    try {
      setLoading(true)
      setConfirming(true)
      const tx = await lavarage.removeTakeProfit(position.pool.publicKey, new PublicKey(position.seed))
      const res = await sendTransaction(tx, addAlert, removeAlert, setConfirming, lavarage.program.provider, wallet, undefined, 'removeTakeProfit', queryClient)
      if (res) {
        trackRemoveTakeProfitSuccessGA()
        setTakeProfitPercent(null)
      }
    }
    catch (error) {
      if (!(error as Error).message.includes('User rejected the request')) {
        trackRemoveTakeProfitCancelledGA()
      }
      else {
        trackRemoveTakeProfitFailedGA()
      }
    }
    finally {
      setConfirming(false)
      setLoading(false)
    }
  }

  const handleModifyTakeProfit = async () => {
    try {
      setLoading(true)
      setConfirming(true)
      const tx = await lavarage.modifyTakeProfit(takeProfitPrice, position.pool.publicKey, new PublicKey(position.seed))
      const res = await sendTransaction(tx, addAlert, removeAlert, setConfirming, lavarage.program.provider, wallet, undefined, 'modifyTakeProfit', queryClient)
      if (res) {
        trackCreateTakeProfitSuccessGA()
        setTakeProfitPercent(takeProfitPercent)
      }
    }
    catch (error) {
      if (!(error as Error).message.includes('User rejected the request')) {
        trackCreateTakeProfitCancelledGA()
      }
      else {
        trackCreateTakeProfitFailedGA()
      }
    }
    finally {
      setConfirming(false)
      setLoading(false)
    }
  }

  return (
    <Modal className='z-40'>
      <div ref={ref} className='bg-vibrant modal-box max-w-[380px] p-[10px] pb-5 text-sm'>
        <div className='mb-[10px] flex grow items-center justify-center gap-[5px] py-[8.5px] text-base'>
          <div className='w-[44px]'/>
          <span>Take Profit</span>
          <span className='bg-alt rounded-full px-[6px] py-0.5 text-[10px] font-normal leading-3'>BETA</span>
        </div>
        <div className='flex flex-col gap-5'>
          <section className='border-main/10 flex flex-col gap-[5px] rounded-lg border p-[10px]'>
            <LabelledValue label='Position Size' value={`${formatSol(currentPositionSize.toNumber())} ${quoteSymbol}`}/>
            <LabelledValue label='Liquidation Price' value={`${formatSol(liquidationPrice)} ${quoteSymbol}`}/>
            <LabelledValue label='Entry Price' value={`${formatSol(position.entryPrice.toNumber())} ${quoteSymbol}`}/>
            <LabelledValue label='Current Price' value={`${formatSol(currentPrice)} ${quoteSymbol}`}/>
            <Conditional if={contractTakeProfitPrice !== undefined}>
              <LabelledValue label='Take Profit Price (Set)' labelClassName='font-bold' value={`${formatSol(contractTakeProfitPrice!)} ${quoteSymbol}`} valueClassName='font-bold'/>
            </Conditional>
          </section>

          <section className='bg-alt/10 flex flex-col gap-[5px] rounded-lg p-[10px] text-sm '>
            <section className='border-main/10 flex flex-col gap-[5px] rounded-lg border px-4 py-[10px]'>
              <div className='flex flex-col gap-[5px] px-6'>
                <div className='flex justify-center text-xs '>
                  <LabelledValue
                    hintPosition='left'
                    label={
                      <div className='flex flex-col items-center'>
                        <span>Set the Desired % for Take Profit</span>
                      </div>
                    }
                    labelClassName='opacity-40 text-xs'
                    labelHint='Enter your desired take profit percentage to set the Take Profit (TP) price. If your position is already positive, the TP % cannot be lower than the current gain. For positions that are negative or neutral, the minimum TP % must be at least 10%.'
                  />
                </div>
                <div className='flex flex-row items-center justify-center'>
                  <input
                    className='text-background mr-[5px] w-full rounded-md p-1 text-right text-base outline-none'
                    id='take-profit'
                    name='take-profit'
                    placeholder={contractTakeProfitPrice !== undefined ? 'Tap to change your TP' : ''}
                    type='number'
                    value={takeProfitPercent ?? ''}
                    onChange={e => setTakeProfitPercent(e.target.value ? Number(e.target.value) : null)}
                  />
                  <span className='opacity-70'>%</span>
                </div>
                <p className='text-gray flex justify-start text-xs'>
                  {`Minimum TP % = ${minTakeProfitPercent}%`}
                </p>
              </div>
              {(isInvalidTakeProfit) && (
                <div className='bg-main/5 text-alt flex items-center justify-center rounded-md p-2 text-xs'>
                  <IconHintRed className='text-alt'/>
                  <span className='ml-[5px]'>
                    Take profit % should be at least
                    {' '}
                    <span className='cursor-pointer underline decoration-dotted' onClick={() => setTakeProfitPercent(minTakeProfitPercent)}>
                      {`${minTakeProfitPercent}%`}
                    </span>
                  </span>
                </div>
              )}

            </section>
            <section>
              <LabelledValue label='Take Profit Price' labelClassName='font-bold' value={`${isInvalidTakeProfit ? '--' : formatSol(takeProfitPrice.toNumber())} ${quoteSymbol}`} valueClassName='font-bold'/>
              <LabelledValue label='Est. TP Position Size' labelHint='This shows the estimated amount you would receive if your TP order is executed, accounting for a 5% price slippage.' value={`${isInvalidTakeProfit ? '--' : formatSol(estimatedPositionSize.toNumber())} ${quoteSymbol}`}/>
              <LabelledValue
                hintInternalComponent={
                  <div className='text-left'>
                    <span>More details on </span>
                    <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/platform/fees-and-interest' rel='noreferrer' target='_blank'>fees</a></span>
                  </div>
                }
                label='Est. Transferred to Wallet'
                value={`${isInvalidTakeProfit ? '--' : formatSol(estimatedProfit.toNumber())} ${quoteSymbol}`}
                valueClassName='text-green font-bold'
              />
            </section>
            <section className='text-gray flex justify-center px-1 py-2 text-center'>
              <span>
                <strong>Note:</strong>
                {' '}
                This feature is currently in BETA. Take Profit orders are executed on a best-effort basis and are not guaranteed. Please monitor your position closely, as there is a possibility that the Take Profit order may not be filled.
              </span>
            </section>
            <section>
              <div className='flex flex-row justify-center gap-[6px]'>
                <Button color='main' disabled={isButtonDisabled} size='s' onClick={onExit}>Cancel</Button>
                <TakeProfitModalButton createTakeProfit={handleSetTakeProfit} modifyTakeProfit={handleModifyTakeProfit} removeTakeProfit={handleRemoveTakeProfit} takeProfitModalButtonMode={takeProfitModalButtonMode}/>
              </div>
            </section>
            <section/>
          </section>
        </div>
      </div>

    </Modal>
  )
})
