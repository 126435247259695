import { Button, Conditional, For } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { IconEmptyClosedPosition } from '@/assets'
import { type TokenInfo } from '@/services'
import { type Position } from '@lavarage/entities'
import { useWallet } from '@solana/wallet-adapter-react'
import { useWalletModal } from '@solana/wallet-adapter-react-ui'
import { useEffect, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { referralService } from '../../../../services/ReferralService.js'
import { useClickOutsideEffect } from '../../../hooks/useClickOutsideEffect.js'
import { usePositions } from '../../../hooks/usePositions.js'
import { PositionCard } from './PositionCard.js'
import { PositionCardsGrid } from './PositionCardsGrid.js'
import { ShareModal } from './ShareModal.js'

export const ClosedPositions = () => {
  const { tokens } = useOutletContext<{ tokens: TokenInfo[] }>()
  const { closedPositions, refresh } = usePositions()
  const { connected } = useWallet()
  const { setVisible } = useWalletModal()
  const [focusedId, setFocusedId] = useState<string | null>(null)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const hasClosedPositions = closedPositions.length > 0
  const [sharingPosition, setSharingPosition] = useState<Position | undefined>(undefined)
  const shareModalClosedRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    refresh()
  }, [refresh])

  const cancelSharingPosition = () => {
    setSharingPosition(undefined)
    refresh()
  }
  useClickOutsideEffect(() => {
    cancelSharingPosition()
  }, { ref: shareModalClosedRef, isActive: !!sharingPosition })

  const { publicKey } = useWallet()
  const [refCode, setRefCode] = useState('')
  useEffect(() => {
    const fetchReferralCode = async () => {
      if (publicKey) {
        const refCodeFromService = await referralService.getReferralCode(publicKey.toBase58())
        if (refCodeFromService) setRefCode(refCodeFromService)
      }
    }
    fetchReferralCode()
  }, [publicKey])

  return (
    <>
      <div className={clsxm('flex flex-1 flex-col py-6')}>
        <Conditional if={hasClosedPositions}>
          <PositionCardsGrid>
            <For of={closedPositions}>
              {(position, idx) => (
                <PositionCard
                  key={position.publicKey}
                  isExpanded={position.publicKey === focusedId}
                  position={position}
                  timezone={timezone}
                  tokens={tokens}
                  onShare={setSharingPosition}
                  onToggleDetails={() => setFocusedId(position.publicKey === focusedId ? null : position.publicKey)}
                />
              )}
            </For>
          </PositionCardsGrid>
        </Conditional>
        <Conditional if={!hasClosedPositions}>
          <div className='m-auto flex max-w-[400px] flex-col items-center justify-center gap-5 text-center text-base'>
            <IconEmptyClosedPosition/>
            <Conditional if={connected}>
              <p>You have no closed positions (only recently closed positions will be displayed).</p>
            </Conditional>
            <Conditional if={!connected}>
              <p>Connect your wallet to view your closed positions.</p>
              <Button color='gradient' size='m' onClick={() => setVisible(true)}>Connect Wallet</Button>
            </Conditional>
          </div>
        </Conditional>
      </div>
      {sharingPosition && (
        <ShareModal
          ref={shareModalClosedRef}
          position={sharingPosition}
          refCode={refCode}
          timezone={timezone}
          onExit={() => setSharingPosition(undefined)}
        />
      )}
    </>
  )
}
