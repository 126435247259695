export const STAKE_ENABLED = import.meta.env.VITE_KILL_STAKING !== 'true'
export const IS_TAKE_PROFIT_ENABLED = import.meta.env.VITE_KILL_TAKE_PROFIT_CURRENCY !== 'true'

export const MODE = import.meta.env.MODE

export const ENABLE_REACT_QUERY_DEVTOOLS = import.meta.env.VITE_ENABLE_REACT_QUERY_DEVTOOLS === 'true'

export const API_HOST = import.meta.env.VITE_API_HOST ?? import.meta.env.VITE_API_HOST === 'prod' ? 'https://app.lavarage.xyz/.netlify/functions' : (() => {
  const url = new URL(window.location.origin)
  url.pathname = '.netlify/functions'
  if (MODE === 'development') url.port = '9999'

  return url
})().toString()

export const SENTRY_DSN = MODE === 'production' ? 'https://8b06ac09342968ac05508da181ad2798@o1329695.ingest.sentry.io/4506348098289664' : ''

export const SENTRY_PROPAGATION_TARGETS = [
  'localhost',
  /^https:\/\/app\.lavarage\.xyz\/\.netlify\/functions/,
  /^https:\/\/lavarage-dapp\.netlify\.app\/\.netlify\/functions/,
]

export const INDEXED_DB_NAME = 'LAVARAGE'

// export const STAKING_DEV_NODE_WALLET = 'HNkCo4HHXEToVYfXGCtUoYah8B9PQRjmDgp8rMze44qs'
// export const DEVNET_TOKEN_ADDRESS = '4biQbz58dzxULa8teS3XN7ECcb8iriAz41DkiM1kX2ex'

export const MARKET_PRICE_REFRESH_INTERVAL = 5_000

// export const ACCRUED_INTEREST_REFRESH_INTERVAL = 300_000
// export const SOL_ATA_FEE = 0.002035

export const FLASH_NOTIFICATION_MESSAGE: string = '🔥 Discover the Hottest Tokens Now! Check Their Performance in the "Trending" Tab! 🚀'
export const FLASH_NOTIFICATION_LINK: string | undefined = 'https://app.lavarage.xyz/trending'
export const IS_ALT_SYNC = import.meta.env.VITE_IS_ALT_SYNC === 'true' && import.meta.env.VITE_IS_PROD !== 'true'
export const IS_PROD = import.meta.env.VITE_IS_PROD === 'true'

export const MIN_TAKE_PROFIT_PERCENT = 10
export const LOWER_THRESHOLD_PERCENT = 5
