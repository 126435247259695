import { type Price } from '@lavarage/entities'
import React, { createContext, useCallback, useContext, useState, type PropsWithChildren } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { ISC_ADDRESS, SOL_ADDRESS } from '../../config.js'
import { useQuoteCurrencyPriceQuery, useQuoteSwapCurrencyPriceQuery, useSolPriceQuery, useUsdcPriceQuery } from '../hooks/queries/usePriceQuery'
import { usePreferencesStore } from '../stores/usePreferencesStore.js'

interface PriceContextValue {
  priceUsdc: Record<string, Price>
  priceSol: Record<string, Price>
  quotePrice: Record<string, Price>
  quoteSwapPrice: Record<string, Price>
  solPriceLoading: boolean
  usdcPriceLoading: boolean
  quotePriceLoading: boolean
  quoteSwapPriceLoading: boolean
  solPriceError?: Error
  usdcPriceError?: Error
  quotePriceError?: Error
  quoteSwapError?: Error
  refresh: () => Promise<void>
  setTokenIds: (tokenIds: string[]) => void
}

const PriceContext = createContext<PriceContextValue | undefined>(undefined)

export const PriceProvider: React.FC<PropsWithChildren<object>> = ({ children }) => {
  // const baseTokenLong = usePreferencesStore(state => state.baseTokenLong)
  const [baseTokenLong, baseTokenSwap, quoteTokenLong, quoteTokenSwap] = usePreferencesStore(useShallow(s => [s.baseTokenLong, s.baseTokenSwap, s.quoteTokenLong, s.quoteTokenSwap]))
  const [tokenIds, setTokenIds] = useState<string[]>([])

  const { solPrice, isLoading: solPriceLoading, error: solError, refetch: refetchSolPrice } = useSolPriceQuery({
    tokenIds: [SOL_ADDRESS],
    against: baseTokenLong?.address,
  })

  const { usdcPrice, isLoading: usdcPriceLoading, error: usdcError, refetch: refetchUsdcPrice } = useUsdcPriceQuery({
    tokenIds: [SOL_ADDRESS, ISC_ADDRESS, baseTokenLong?.address, quoteTokenSwap?.address, baseTokenSwap?.address, ...tokenIds],
  })

  const { quotePrice, isLoading: quotePriceLoading, error: quoteError, refetch: refetchQuotePrice } = useQuoteCurrencyPriceQuery({
    tokenIds: [SOL_ADDRESS, baseTokenLong?.address, baseTokenSwap?.address, ...tokenIds],
    quoteToken: quoteTokenLong?.address,
  })
  const { quoteSwapPrice, isLoading: quoteSwapPriceLoading, error: quoteSwapError, refetch: refetchSwapQuotePrice } = useQuoteSwapCurrencyPriceQuery({
    tokenIds: [SOL_ADDRESS, baseTokenSwap?.address],
    quoteTokenSwap: quoteTokenSwap?.address,
  })

  const refresh = useCallback(async () => {
    refetchSolPrice()
    refetchUsdcPrice()
    refetchQuotePrice()
    refetchSwapQuotePrice()
  }, [refetchSolPrice, refetchUsdcPrice, refetchQuotePrice, refetchSwapQuotePrice])

  const contextValue = {
    priceUsdc: usdcPrice || {},
    priceSol: solPrice || {},
    quotePrice: quotePrice || {},
    quoteSwapPrice: quoteSwapPrice || {},
    solPriceLoading,
    usdcPriceLoading,
    quotePriceLoading,
    quoteSwapPriceLoading,
    solPriceError: solError || undefined,
    usdcPriceError: usdcError || undefined,
    quotePriceError: quoteError || undefined,
    quoteSwapPriceError: quoteSwapError || undefined,
    refresh,
    setTokenIds,
  }

  return <PriceContext.Provider value={contextValue}>{children}</PriceContext.Provider>
}

export const usePriceContext = () => {
  const context = useContext(PriceContext)
  if (context === undefined) {
    throw new Error('usePriceContext must be used within a PriceProvider')
  }
  return context
}
