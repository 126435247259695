import { useWallet } from '@solana/wallet-adapter-react'
import { type ChangeEvent, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { type TimeFrameData } from '../../../../functions/database/index.js'
import { IconDown, IconLightning } from '../../../assets/index.js'
import { QUOTE_TOKENS } from '../../../config.js'
import { Conditional, TokenImage } from '../../components/index.js'
import { useTrendingTokensQuery } from '../../hooks/queries/useTrendingTokensQuery.js'
import { usePreferencesStore } from '../../stores/index.js'
import { Layout } from '../layout/Layout.js'
import { SearchInput } from './components/SearchInput.js'
import { TrendingTable } from './components/TrendingTable.js'
const timeFrames = ['30m', '1h', '2h', '4h', '8h', '24h']

export function TrendingPage() {
  const [quoteTokenLong, setQuoteTokenLong] = usePreferencesStore(useShallow(s => [s.quoteTokenLong, s.setQuoteTokenLong]))
  const [inputLeverageValue, setInputLeverageValue] = useState<string>('2X')
  const [inputAmountValue, setInputAmountValue] = useState<string>('0.5')
  const [timeOption, setTimeOption] = useState<string>('24h')

  const handleInputLeverageChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputLeverageValue(e.target.value)
  }
  const handleAmountInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputAmountValue(e.target.value)
  }

  const [isDropdownTimeOpen, setIsDropdownTimeOpen] = useState(false)
  const toggleDropdownTime = () => setIsDropdownTimeOpen(!isDropdownTimeOpen)
  const closeTimeDropdown = () => setIsDropdownTimeOpen(false)

  const [isDropdownQuoteOpen, setIsDropdownQuoteOpen] = useState(false)
  const toggleDropdownQuote = () => setIsDropdownQuoteOpen(!isDropdownQuoteOpen)
  const closeQuoteDropdown = () => setIsDropdownQuoteOpen(false)
  const { trendingTokens: trendingTokens = [], isLoading: isTrendingTokensLoading } = useTrendingTokensQuery('all')

  const [filteredTokens, setFilteredTokens] = useState(trendingTokens)
  return (
    <Layout backgroundType='default'>
      <div className='bg-background flex w-full max-w-[1240px] flex-col rounded-[8px] px-[10px] pt-5 text-center'>
        <section className='desk:flex-row desk:gap-0 desk:items-center mb-5 flex flex-col items-start justify-between gap-2 text-center'>
          <span className='font-berpatroli desk:text-[30px] text-[30px] leading-[32px]'>TRENDING TOKENS</span>
          <div className='desk:gap-5 desk:w-auto flex w-full flex-wrap items-center justify-between gap-4'>
            <div className='flex min-w-0 flex-1'>
              <SearchInput tokens={trendingTokens} onFilter={setFilteredTokens}/>
            </div>
            <div className='relative shrink-0'>
              <button
                className='bg-alt/10 hover:bg-alt/20 flex h-[36px] items-center gap-[5px] rounded-[8px] border-none px-2'
                onClick={toggleDropdownTime}
              >
                {`${timeOption}`}
                <IconDown/>
              </button>
              {isDropdownTimeOpen && (
                <ul
                  className='dropdown-content bg-background/95 absolute right-0 z-[1] mt-2 rounded-lg'
                  tabIndex={0}
                >
                  {timeFrames.map((option, index) => (
                    <li
                      key={index}
                      className={`bg-alt/10 hover:bg-alt/40 w-[59px] cursor-pointer py-1 transition ${
                        index === 0 ? 'rounded-t-lg' : ''
                      } ${index === timeFrames.length - 1 ? 'rounded-b-lg' : ''}`}
                      onClick={() => {
                        setTimeOption(option)
                        closeTimeDropdown()
                      }}
                    >
                      <span>
                        {option}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <Conditional if={false}>
              <div className=' flex h-[36px] items-center rounded-[8px]'>
                <div className='bg-main/10 flex h-full items-center rounded-l-[8px] rounded-r-none pr-2'>
                  <IconLightning/>
                  BUY
                </div>
                <input
                  className='bg-main text-gray flex h-full w-[30px] items-center justify-center text-center text-sm outline-none '
                  type='text'
                  value={inputLeverageValue}
                  onChange={handleInputLeverageChange}
                />
                <div className='bg-gray h-full w-[2px]'/>
                <input
                  className='bg-main text-gray flex h-full w-[63px] items-center justify-center pr-2 text-right text-sm outline-none'
                  type='text'
                  value={inputAmountValue}
                  onChange={handleAmountInputChange}
                />
                <div className='relative'>
                  <button
                    className='bg-alt/10 btn btn-sm hover:bg-alt/20 flex h-[36px] items-center gap-[5px] rounded-l-none rounded-r-[8px] border-none px-2'
                    onClick={toggleDropdownQuote}
                  >
                    <TokenImage altName={quoteTokenLong.name} logoURI={quoteTokenLong.logoURI} tag={undefined}/>
                    <IconDown/>
                    {/* <span className='min-w-0 truncate px-[5px]'>{quoteTokenLong.symbol}</span> */}
                  </button>
                  {isDropdownQuoteOpen && (
                    <ul
                      className='dropdown-content bg-background/95 absolute right-0 z-20 mt-2 flex flex-col rounded-lg'
                      tabIndex={0}
                    >
                      {QUOTE_TOKENS.map((option, index) => (
                        <li
                          key={index}
                          className={`bg-alt/10 hover:bg-alt/40 flex cursor-pointer flex-row items-center justify-center gap-[10px] whitespace-nowrap px-[20px] py-2 transition  ${
                            index === 0 ? 'rounded-t-lg' : ''
                          } ${index === QUOTE_TOKENS.length - 1 ? 'rounded-b-lg' : ''}`}
                          onClick={() => {
                            setQuoteTokenLong(option)
                            closeQuoteDropdown()
                          }}
                        >
                          <TokenImage altName={option.name} logoURI={option.logoURI} tag={undefined}/>
                          <span className=''>{option.symbol}</span>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </Conditional>
          </div>
        </section>
        <TrendingTable isTrendingTokensLoading={isTrendingTokensLoading} timeFrame={timeOption as keyof TimeFrameData} trendingTokens={filteredTokens}/>
        <div className='flex justify-center py-[11px]'>
          <span>
            Missing a token on this list?
            {' '}
            <a href='https://discord.com/invite/lavarage' rel='noopener noreferrer' target='_blank'>
              <span className='text-alt border-b hover:border-b-0'>Let us know on Discord!</span>
            </a>
          </span>
        </div>
      </div>
    </Layout>
  )
}
