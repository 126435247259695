import { useLavarage } from '@/app/providers/LavarageProvider'
import { web3 } from '@coral-xyz/anchor'
import { type Position } from '@lavarage/entities'
import { getAccount, getAssociatedTokenAddressSync } from '@solana/spl-token'
import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useState } from 'react'
import { JUPITER_PLATFORM_FEE_BPS_HAS_LAVA_NFT, JUPITER_PLATFORM_FEE_BPS_NO_LAVA_NFT } from '../../config.js'
import { type LavarageService } from '../../services/LavarageService.js'
import { type LavarageV2Service } from '../../services/LavarageV2Service.js'
import { jupiterSource } from '../../services/sources/JupiterSource.js'

export const fetchSwapInfo = async (lavarage: LavarageService | LavarageV2Service, position: Position, hasLavaRockNFT: boolean, slippage: number) => {
  if (!lavarage.program.provider.publicKey) throw new Error('Public key not found')
  if (!position.pool.baseCurrency || !position.pool.quoteCurrency) throw new Error('Base or quote currency not found')
  const jupiterPlatformFeeBps = hasLavaRockNFT ? JUPITER_PLATFORM_FEE_BPS_HAS_LAVA_NFT : JUPITER_PLATFORM_FEE_BPS_NO_LAVA_NFT

  const positionATA = getAssociatedTokenAddressSync(new web3.PublicKey(position.pool.baseCurrency?.address), new web3.PublicKey(position.publicKey), true)
  const balance = await getAccount(lavarage.program.provider.connection, positionATA)
  const quoteTokenAddress = position.pool.quoteCurrency?.address
  const info = await jupiterSource.getSwapIx(
    position.pool.baseCurrency?.address,
    quoteTokenAddress,
    BigNumber(Number(balance.amount)),
    slippage || 0.5,
    lavarage.program.provider.publicKey?.toBase58(),
    jupiterPlatformFeeBps,
    undefined,
    false,
  )
  return info
}
export function useConfirmedSell(position: Position, hasLavaRockNFT: boolean, slippage: number): number {
  if (position.state === 'closed') return 0
  const [confirmedSellInSol, setConfirmedSellInSol] = useState(0)
  const jupiterPlatformFeeBps = hasLavaRockNFT ? JUPITER_PLATFORM_FEE_BPS_HAS_LAVA_NFT : JUPITER_PLATFORM_FEE_BPS_NO_LAVA_NFT
  const lavarages = useLavarage()
  const quoteToken = position.pool.quoteCurrency
  if (!quoteToken) return 0
  const quoteTokenDecimals = quoteToken.decimals
  if (!quoteTokenDecimals) {
    console.error('Quote token decimals not found')
    return 0
  }
  const lavarage = quoteToken.symbol === 'SOL' ? lavarages[0] : lavarages[1]
  const updateConfirmedSellInSol = useCallback(async () => {
    try {
      const info = await fetchSwapInfo(lavarage, position, hasLavaRockNFT, slippage)
      const sellInQuoteTokenFromJup = Number(info.quoteResponse.outAmount) / 10 ** quoteTokenDecimals
      const jupiterPlatformFee = Number(sellInQuoteTokenFromJup) * jupiterPlatformFeeBps / 10_000
      // const jupiterPlatformFee = isAvoidJupFee ? 0 : Number(sellInQuoteTokenFromJup) * jupiterPlatformFeeBps / 10_000
      setConfirmedSellInSol(sellInQuoteTokenFromJup + jupiterPlatformFee)
    }
    catch (error) {
      console.error('Error fetching swap info:', error)
      setConfirmedSellInSol(0)
    }
  }, [position])

  useEffect(() => {
    updateConfirmedSellInSol()
  }, [updateConfirmedSellInSol])

  return confirmedSellInSol
}
