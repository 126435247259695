// import { type TephraUser } from '../../../../../functions/tephras.mjs'
import { Identicon } from '../../../components/Identicon.js'
import { useTopTephraUsersQuery } from '../../../hooks/queries/useTopTephraUsers.js'

export function LeaderBoard() {
  const {
    topTephraUsers,
    isLoading,
    isError,
  } = useTopTephraUsersQuery()

  const getPlaceByIndex = (i: number) => {
    const n = i + 1
    if (n < 10) {
      return `0${n}`
    }
    else {
      return n
    }
  }

  const prettyWallet = (wallet: string) => {
    return `${wallet.slice(0, 4)}..${wallet.slice(-3)}`
  }

  const tephraPrizeDistribution = [['AyXmbLPCLV5A4uwoyiJwGCpJUwUFfnyt7mGpquYcaLoT', '5,200,417'], ['8nx3GKCeAm6NZBf7od5waTwUF63MnAXBcXNiVrv1pfNd', '1,673,759'], ['9iEqBa3xFLcRKQDX8FFvuHX5knjj7CbcyaysEzAjhuQ8', '1,670,711'], ['TuYRP7ha3HQEaW4sw3ozah6snNFPQ6Qd1Zp5jGSfa3g', '1,274,963'], ['J9u2V5wM1rAMww8JBHddj1moMGQJpdTeZ5Uyv8RgCshN', '952,412'], ['Ef9Sioi4msqLyf6sykA5a7cc6DGzJx7tgWFoN3QrsZqz', '745,732'], ['CvNoQMfK712xSu6gL67J2YJ5SJAhG4TTZs4R6g847Rj', '605,077'], ['31Q7Tb2LCZ3SxipBGrUgTa6trk3e2Z6cpJefGss7eQaw', '579,726'], ['aqpqwgoL4PK5HtxEtshJ5CJihdCyDkEswufPhpeaFVX', '558,015'], ['A6qH8847vcxf2KZuAquUmF1umT29nqdxQ8PiyjZE19gJ', '547,058']]

  // const getProgress = (user: TephraUser) => {
  //   const totalTrading = user.tephras.borrowedAmount + user.tephras.initialMargin + user.tephras.interestPaid + user.tephras.openPositionSize + user.tephras.profit + user.tephras.repaidPositionSize + user.tephras.soldPositionSize
  //   const total = user.tephras.total
  //   const tradingProgress = totalTrading / total * 100
  //   const socialProgress = user.tephras.social / total * 100
  //   const totalProgress = tradingProgress + socialProgress
  //   const progress = { tradingProgress, socialProgress, totalProgress }
  //   return progress
  // }

  return (
    <section className='bg-main/5 flex w-full max-w-[440px] flex-col items-center justify-center rounded-[8px] px-5 py-10'>
      <p className='mb-[24px] text-xl font-bold leading-[24.2px]'>
        <span>Tephra Prize Distribution</span>
      </p>
      {/* <Conditional if={isLoading}>
        <span>Loading...</span>
      </Conditional>
      <Conditional if={isError}>
        <span>Failed to load</span>
      </Conditional> */}
      {/* <Conditional if={!isLoading && !isError}> */}
      <div className='flex w-full flex-col gap-[12px]'>
        {tephraPrizeDistribution.map(([wallet, tephras], index) => (
          <div key={index} className='flex w-full items-center justify-between'>
            <div className='flex items-center gap-2'>
              <span className='w-[30px] text-right text-base'>{getPlaceByIndex(index)}</span>
              <Identicon className='size-[40px] rounded-full' walletAddress={wallet}/>
              <span className='text-sm'>{prettyWallet(wallet)}</span>
              {/* <Identicon className='size-[40px] rounded-full' walletAddress={user.wallet}/>
              <span className='text-sm'>{prettyWallet(user.wallet)}</span> */}

            </div>
            <div className='flex shrink-0 items-center gap-2'>

              <span className='text-gradient text-base font-bold'>{tephras}</span>
              {/* <span className='text-gradient text-base font-bold'>{formatSol(user.tephras.total)}</span> */}
              {/* <SimpleProgressComponent color='alt' progressInPercent={getProgress(user).tradingProgress}><IconTradingProgress/></SimpleProgressComponent>
                <SimpleProgressComponent color='main' progressInPercent={getProgress(user).socialProgress}><IconSocialProgress/></SimpleProgressComponent> */}
            </div>
          </div>
        ))}
      </div>
      {/* </Conditional> */}
    </section>
  )
}
