import { type HTMLAttributes } from 'react'
import { IconCheckGreen, IconCheckRed } from '../../../../assets/index.js'
import { Conditional } from '../../../components/Conditional.js'

type Props = HTMLAttributes<HTMLDivElement> & {
  isCheckPassed: boolean
  text: string
  exactValue?: number | null
}

export const ChecklistCol = ({
  isCheckPassed,
  text,
  exactValue,
}: Props) => {
  return (
    <div className='flex w-[59px] flex-col gap-[2px]'>
      <Conditional if={exactValue}>
        <span className={`${isCheckPassed ? 'text-green' : 'text-alt'} text-xs`}>{exactValue && `${Math.round(exactValue * 100)}%`}</span>
      </Conditional>
      <Conditional if={!exactValue}>
        {isCheckPassed ? <IconCheckGreen/> : <IconCheckRed/>}
      </Conditional>
      <span className={isCheckPassed ? '' : 'text-gray'}>
        {text}
      </span>
    </div>
  )
}
