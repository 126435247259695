import { LSpan } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { type ChangeEvent, type FocusEvent, useState } from 'react'

type FocusControlledNumericInputProps = {
  value: string
  defaultValue?: number
  setValue: (value: string) => void
  inputRef?: React.RefObject<HTMLInputElement>
  MIN_VALUE: number
  MAX_VALUE: number | null
  id?: string
  disabled?: boolean
  refresh?: () => void
  isLeverage?: boolean
  isSlippage?: boolean
}

export const FocusControlledNumericInput = ({
  value,
  setValue,
  inputRef,
  MIN_VALUE,
  MAX_VALUE,
  id,
  disabled = false,
  refresh,
  defaultValue,
  isLeverage,
  isSlippage,
}: FocusControlledNumericInputProps) => {
  const [isFocused, setIsFocused] = useState(false)
  const [typingValue, setTypingValue] = useState<string>(value)
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value

    setTypingValue(newValue)

    if (refresh) {
      refresh()
    }
  }
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    const numericValue = parseFloat(inputValue)

    if (isNaN(numericValue)) {
      setTypingValue(defaultValue?.toString() || MIN_VALUE.toString())
    }
    else {
      const clampedValue = Math.max(Math.min(numericValue, MAX_VALUE), MIN_VALUE)
      const roundedValue = Math.round(clampedValue * 10) / 10
      setValue(roundedValue.toString())
      setTypingValue(roundedValue.toString())
      if (roundedValue > 0) {
        if (refresh) {
          refresh()
        }
      }
    }
    setIsFocused(false)
  }

  return isFocused ? (
    <input
      ref={inputRef}
      className={clsxm(
        'flex h-full w-full text-center focus:border-0 focus:border-transparent focus:outline-none',
        'text-main text-base',
        'bg-transparent',
        { 'max-w-[120px]': isSlippage },
        { 'max-w-[160px]': isLeverage },
      )}
      disabled={disabled}
      id={id}
      max={MAX_VALUE}
      min={MIN_VALUE}
      step={0.1}
      type='string'
      value={isFocused ? typingValue : value}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={() => {
        setIsFocused(true)
        setTypingValue(value)
      }}
    />
  ) : (
    <div
      className={clsxm('flex h-full w-full items-center justify-center', {
        'max-w-[160px]': !disabled,
      })}
      onClick={() => !disabled && setIsFocused(true)}
    >
      {isLeverage && disabled && <span className='w-full text-center'>No Available Leverage</span>}
      {isSlippage && !disabled && <span>{value}</span>}
      {!disabled && isLeverage && <LSpan isLoading={!MAX_VALUE} text={`Leverage ${value}x`}/>}
      {isSlippage && <span>%</span>}
    </div>
  )
}
