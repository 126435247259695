import axios, { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import { API_HOST } from '../app/app.config.js'
import { BaseService } from './BaseService'

export class ReferralService extends BaseService {
  private referralCodeGenerated = false
  private refereeTmpRegisteredWithCode: string | undefined = undefined
  private isRefereeFinalized = false

  async generateReferralCode(wallet: string) {
    const cookieKey = `generateReferralCode_${wallet}`
    const previousParams = Cookies.get(cookieKey)

    if (previousParams === JSON.stringify({ wallet })) {
      return
    }

    try {
      if (this.referralCodeGenerated) {
        return
      }

      this.referralCodeGenerated = true
      await axios.post(`${API_HOST}/referral-code`, { wallet })
      Cookies.set(cookieKey, JSON.stringify({ wallet }), { expires: 30 })
    }
    catch (error) {
      if (error instanceof AxiosError && error.status === 400) {
        return
      }
      throw error
    }
  }

  async getReferralCode(wallet: string): Promise<string | undefined> {
    const cookieRefCodeKey = `getReferralCode_${wallet}`
    const cookieData = Cookies.get(cookieRefCodeKey)
    const cookieRefCode = cookieData ? JSON.parse(cookieData).referralCode : undefined
    if (cookieRefCode) {
      return cookieRefCode
    }

    try {
      const { data } = await axios.get(`${API_HOST}/referral-code?wallet=${wallet}`)
      Cookies.set(cookieRefCodeKey, JSON.stringify({ referralCode: data.code }), { expires: 30 })
      return data.code
    }
    catch (error) {
      if (error instanceof AxiosError && error.status === 404) {
        return undefined
      }
      throw error
    }
  }

  async registerReferee(wallet: string, referralCode: string | undefined, isTrade = false) {
    const cookieKey = `registerReferee_${wallet}_${referralCode || 'noCode'}`
    const previousParams = Cookies.get(cookieKey)

    if (previousParams === JSON.stringify({ referralCode, isTrade })) {
      return
    }

    if (this.refereeTmpRegisteredWithCode === referralCode && !isTrade) {
      return
    }
    if (this.isRefereeFinalized) {
      return
    }

    if (!isTrade) {
      this.refereeTmpRegisteredWithCode = referralCode
    }
    else {
      this.isRefereeFinalized = isTrade
    }

    if (referralCode) {
      await this.http.put(`${API_HOST}/referral-code?code=${referralCode}`, {
        referee: {
          add: wallet,
        },
        isTrade,
      })
    }
    else {
      await this.http.put(`${API_HOST}/referral-code`, {
        referee: {
          add: wallet,
        },
        isTrade,
      })
    }
    Cookies.set(cookieKey, JSON.stringify({ referralCode, isTrade }), { expires: 30 })
  }
}

export const referralService = new ReferralService()
