import type { Position } from '@lavarage/entities'
import { log, warn } from '@lavarage/utils'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import BigNumber from 'bignumber.js'
import { createContext, useCallback, type PropsWithChildren } from 'react'
import { API_HOST } from '../app.config.js'

type Props = PropsWithChildren<{
  userAddress?: string
}>

type ContextValue = {
  openPositions: Position[]
  closedPositions: Position[]
  error?: Error
  isLoading: boolean
  refresh: () => void
}

async function fetchPositions(userAddress?: string): Promise<Position[]> {
  if (!userAddress) {
    log('Fetching positions...', 'SKIP', 'No user address provided')

    return []
  }

  try {
    const res = await axios.get(`/positions?user=${userAddress}`, { baseURL: API_HOST })
    const positions: Position[] = res.data.map((t: any) => ({
      ...t,
      borrowedAmount: BigNumber(t.borrowedAmount),
      collateralSize: BigNumber(t.collateralSize),
      entryPrice: BigNumber(t.entryPrice),
      initialLeverage: BigNumber(t.initialLeverage),
      initialMargin: BigNumber(t.initialMargin),
      initialPositionSize: BigNumber(t.initialPositionSize),
      interestAccrued: BigNumber(t.interestAccrued),
      interestRate: BigNumber(t.interestRate),
      openDate: new Date(t.openDate),
      closeDate: t.closeDate ? new Date(t.closeDate) : undefined,
      pnl: t.pnl ? BigNumber(t.pnl) : undefined,
      positionSize: BigNumber(t.positionSize),
      price: BigNumber(t.price),
      roi: t.roi ? BigNumber(t.roi) : undefined,
      updatedAt: t.updatedAt ? new Date(t.updatedAt) : undefined,
      pool: {
        ...t.pool,
        apr: BigNumber(t.pool.apr),
        currentExposure: BigNumber(t.pool.currentExposure),
        maxExposure: BigNumber(t.pool.maxExposure),
        maxBorrow: BigNumber(t.pool.maxBorrow),
      },
      interestCollected: BigNumber(t.interestCollected),
      takeProfitPrice: t.takeProfitPrice ? BigNumber(t.takeProfitPrice) : undefined,
    }))

    log('Fetching positions...', 'OK', `${positions.length} positions`)

    return positions
  }
  catch (err) {
    warn('Fetching positions...', 'ERR', `${err}`)

    throw err
  }
}

export const PositionsContext = createContext<ContextValue>({
  openPositions: [],
  closedPositions: [],
  isLoading: false,
  refresh: () => {},
})

export function PositionsProvider({ children, userAddress }: Props) {
  const { data, error, isPending, refetch } = useQuery({
    refetchInterval: 60_000,
    refetchIntervalInBackground: true,
    queryKey: ['positions', userAddress],
    queryFn: () => fetchPositions(userAddress),
    select: t => ({
      open: t.filter((p: Position) => p.state === 'open'),
      closed: t.filter((p: Position) => p.state === 'closed'),
    }),
  })

  const refresh = useCallback(() => refetch(), [refetch])
  const openPositions = data?.open.sort((a, b) => b.openDate.getTime() - a.openDate.getTime()) ?? []
  const closedPositions = data?.closed.sort((a, b) => {
    const dateA = a.closeDate?.getTime() ?? 0
    const dateB = b.closeDate?.getTime() ?? 0
    return dateB - dateA
  }) ?? []

  return (
    <PositionsContext.Provider
      value={{
        openPositions,
        closedPositions,
        error: error ?? undefined,
        isLoading: isPending,
        refresh,
      }}
    >
      {children}
    </PositionsContext.Provider>
  )
}
