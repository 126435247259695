import React, { createContext, useCallback, useContext, type PropsWithChildren } from 'react'
import { type TokenInfo } from '../../services/types.js'
import { useTokensQuery } from '../hooks/queries/useTokensQuery.js'

interface TokensContextValue {
  allTokens: TokenInfo[]
  refresh: () => Promise<void>
  isLoading: boolean
}

const TokensContext = createContext<TokensContextValue | undefined>(undefined)

export const TokensProvider: React.FC<PropsWithChildren<object>> = ({ children }) => {
  // const baseTokenLong = usePreferencesStore(state => state.baseTokenLong)

  const { tokens: allTokensFromQuery = [], refetch: fetchTokens, isLoading } = useTokensQuery('all')

  const refresh = useCallback(async () => {
    fetchTokens()
  }, [fetchTokens])

  const contextValue = {
    allTokens: allTokensFromQuery,
    refresh,
    isLoading,
  }

  return <TokensContext.Provider value={contextValue}>{children}</TokensContext.Provider>
}

export const useTokensContext = () => {
  const context = useContext(TokensContext)
  if (context === undefined) {
    throw new Error('useTokensContext must be used within a TokensProvider')
  }
  return context
}
