export const PROGRAM_ID = 'CRSeeBqjDnm3UPefJ9gxrtngTsnQRhEJiTA345Q83X3v'
export const PROGRAM_IDS = ['CRSeeBqjDnm3UPefJ9gxrtngTsnQRhEJiTA345Q83X3v', '1avaAUcjccXCjSZzwUvB2gS3DzkkieV2Mw8CjdN65uu']
export const SOLANA_RPC = 'https://solitary-broken-pallet.solana-mainnet.quiknode.pro/e70f49ff42c8fbda17f4fd2041e184af8c94f97b'
export const FALLBACK_RPC = 'https://solana-mainnet.g.alchemy.com/v2/yTBaNPPya9CJDsgBHq-dg89gpjzbFzbQ/'
// export const SOLANA_WS_RPC = 'wss://solana-mainnet.core.chainstack.com/ws/8cde996495659fabe0b76a1eb576a995'
export const SOLANA_WS_RPC = 'wss://solitary-broken-pallet.solana-mainnet.quiknode.pro/e70f49ff42c8fbda17f4fd2041e184af8c94f97b'
export const STAKING_PROGRAM_ID = '85vAnW1P89t9tdNddRGk6fo5bDxhYAY854NfVJDqzf7h'
export const LSTSOL_ADDRESS = '6M9o6GD1zPWZdtR7D8EMs25vb2ssTBs8jMnfJXrakFMB'
// export const FALLBACK_RPC = 'https://rpc.lavarave.wtf/'
export const COLLECTION_KEY = '3HeEvzCyUK3M7Q2xkvMeZojAnVYmn3yHGHHJHmRktUVw'
export const NODE_WALLET = 'GKXdLXFicCNYD4w2muLKrwdnP9omDWvrnWEgLAqNRXx5'
export const SOL_ADDRESS = 'So11111111111111111111111111111111111111112'
export const ISC_ADDRESS = 'J9BcrQfX4p9D1bvLzRNCbMDv8f44a9LFdeqNE4Yk2WMD'
export const USDC_ADDRESS = 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'
export const WIF_ADDRESS = 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm'
export const SOL_LOGO_URI = 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/So11111111111111111111111111111111111111112/logo.png'
export const ISC_LOGO_URI = 'https://raw.githubusercontent.com/theISCTeam/isc_meta/master/logo.png'
export const USDC_LOGO_URI = 'https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v/logo.png'
export const LSTSOL_LOGO_URI = 'https://olive-brilliant-opossum-104.mypinata.cloud/ipfs/QmSKejtq8LzmQhtaJqQoK6Z4D8UyBFDAwjWFaAwLRU8gF2'
export const JUPITER_PLATFORM_FEE_BPS_HAS_LAVA_NFT = 42 // 0.42%
export const JUPITER_PLATFORM_FEE_BPS_NO_LAVA_NFT = 69 // 0.69%
export const PLATFORM_FEE = 0.5 // 0.5%
export const MIN_PLATFORM_FEE_IN_SOL = 0.005 // 0.005 SOL or 0.5%
export const MIN_PLATFORM_FEE_IN_STABLE = 1 // 0.005 USDC or 0.5%
export const PROFIT_FEE = 5 // 5%
export const FIAT_DECIMAL_POINTS = 2
export const CRYPTO_DECIMAL_POINTS = 4
export const MIN_LEVERAGE = 1.1
export const LIQUIDATION_LTV = 90
export const SOL_TOKEN = { address: SOL_ADDRESS, symbol: 'SOL', name: 'Solana', logoURI: SOL_LOGO_URI, decimals: 9, tags: [], isNewCoin: false, isHotCoin: false, whitelistedForAddress: [ISC_ADDRESS, USDC_ADDRESS], availableLiquidity: null, dailyInterest: null, daily_volume: null, created_at: '2024-04-26T10:56:58.893768Z', extension: {}, minted_at: null, permanent_delegate: null }
export const ISC_TOKEN = { address: ISC_ADDRESS, symbol: 'ISC', name: 'International Stable Currency', logoURI: ISC_LOGO_URI, decimals: 6, tags: [], isNewCoin: false, isHotCoin: false, whitelistedForAddress: [SOL_ADDRESS, USDC_ADDRESS], availableLiquidity: null, dailyInterest: null, daily_volume: null, created_at: '2024-04-26T10:56:58.893768Z', extension: {}, minted_at: null, permanent_delegate: null }
export const USDC_TOKEN = { address: USDC_ADDRESS, symbol: 'USDC', name: 'USD Coin', logoURI: USDC_LOGO_URI, decimals: 6, tags: [], isNewCoin: false, isHotCoin: false, whitelistedForAddress: [SOL_ADDRESS, ISC_ADDRESS], availableLiquidity: null, dailyInterest: null, daily_volume: null, created_at: '2024-04-26T10:56:58.893768Z', extension: {}, minted_at: null, permanent_delegate: null }
export const WIF_TOKEN = { address: 'EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm', symbol: '$WIF', name: 'dogwifhat', logoURI: 'https://bafkreibk3covs5ltyqxa272uodhculbr6kea6betidfwy3ajsav2vjzyum.ipfs.nftstorage.link', decimals: 6, tags: [], isNewCoin: false, isHotCoin: false, whitelistedForAddress: [SOL_ADDRESS, USDC_ADDRESS, ISC_ADDRESS], availableLiquidity: null, dailyInterest: null, daily_volume: null }
export const QUOTE_TOKENS = [SOL_TOKEN, USDC_TOKEN]
export const BANNED_TOKENS = [
  'C5vQ5W9ymUpRK1UMmNHc8eLCN87xi9trGEzvvGaZt8EB',
  '2N53P6hfvFqnApGL71oyXnocAUpyCYBeNtcYcRDy1Qsg',
  'AjeoZ39HTivE5GuYS9DwEwRLbi7YJgoE1ADVqoa5YXDF',
]
export const DAPP_VERSION = '1.0.4'

export const DEFAULT_MIN = {
  SOL: 0.05,
  USDC: 10,
}
export const MIN_LOAN_LIMIT = DEFAULT_MIN
export const MIN_REMAINING_LOAN_BALANCE = DEFAULT_MIN
export const MIN_TRANSACTION_AMOUNT = DEFAULT_MIN
export const DELEGATE_OPERATOR_ADDRESS = '6dA5GTDPWxnw3gvjoy3vYBDyY7iETxcTJzt8RqF9i9MV'
export const TAKE_PROFIT_FEE = 1 // 1%
