import { IconCopied, IconCopyToClipboard } from '@/assets/svgs'
import clsx from 'clsx'
import { forwardRef, useState, type HTMLAttributes } from 'react'
import { Conditional } from './Conditional.js'

type Props = HTMLAttributes<HTMLDivElement> & {
  textToCopy: string
}

export const CopyToBuffer = forwardRef<HTMLDivElement, Props>(({ className, textToCopy, ...props }, ref) => {
  const [isCopied, setIsCopied] = useState(false)
  const [isFading, setIsFading] = useState(false)

  const onCopy = () => {
    if (!textToCopy) return

    navigator.clipboard.writeText(textToCopy)
    setIsCopied(true)
    setIsFading(false)

    setTimeout(() => {
      setIsFading(true)
      setTimeout(() => {
        setIsCopied(false)
        setIsFading(false)
      }, 500)
    }, 1000)
  }

  return (
    <div
      {...props}
      ref={ref}
      className={clsx(className, 'flex items-center')}
    >
      <Conditional if={!isCopied}>
        <button aria-label='Copy to clipboard' className='opacity-40 transition hover:opacity-100' onClick={onCopy}>
          <IconCopyToClipboard/>
        </button>
      </Conditional>
      <Conditional if={isCopied}>
        <div
          className={clsx('transition-opacity duration-500', {
            'opacity-100': !isFading,
            'opacity-0': isFading,
          })}
        >
          <IconCopied/>
        </div>
      </Conditional>
    </div>
  )
})
